export const CHECK_APP_VERSION = "CHECK_APP_VERSION";
export const GET_OTP = "GET_OTP";
export const LOAD_CLIENTS = "LOAD_CLIENTS";
export const LOAD_SSO_PROVIDERS = "LOAD_SSO_PROVIDERS";
export const SSO_LOGIN = "SSO_LOGIN";
export const TWO_FACTOR_LOGIN = "TWO_FACTOR_LOGIN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REFRESH_PERMISSIONS = "REFRESH_PERMISSIONS";
export const REFRESH_LOGIN = "REFRESH_LOGIN";
export const RESET_STATE = "RESET_STATE";
export const SAVE_CLIENT = "SAVE_CLIENT";
export const SAVE_INITIAL_PERSON = "SAVE_INITIAL_PERSON";
export const SAVE_USER_INFO = "SAVE_USER_INFO";
export const SET_PERSON_SUBORDINATES = "SET_PERSON_SUBORDINATES";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_AUTHENTICATING = "SET_AUTHENTICATING";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_TWO_FACTOR = "SET_TWO_FACTOR";
export const SET_SUBMITTED = "SET_SUBMITTED";
export const SET_CLIENTS = "SET_CLIENTS";
export const SET_ERRORS = "SET_ERRORS";
export const SET_LAST_VERSION_CHECK = "SET_LAST_VERSION_CHECK";
export const SET_LOADING = "SET_LOADING";
export const SET_USER = "SET_USER";
export const SET_PERSON = "SET_PERSON";
export const SET_SLIDES = "SET_SLIDES";
export const SET_SELECTED_CLIENT_ID = "SET_SELECTED_CLIENT_ID";
export const SOCKET_AUTH = "SOCKET_AUTH";
export const TOGGLE_CLIENT = "TOGGLE_CLIENT";
export const USER_AUTHENTICATED = "USER_AUTHENTICATED";
