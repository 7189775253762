import {
  ADD_GROUP_SHIFT,
  DELETE_PERIOD_FROM_QUEUE,
  DELETE_QUEUED_PERIOD_DATA,
  INITIAL_PERIOD_LOADED,
  LOAD_CLIENT,
  LOAD_PERIOD,
  LOAD_PHONE_NUMBERS,
  LOAD_TIME_BANK_BALANCES,
  POP_PERIOD_REQUEST,
  QUEUE_PERIOD,
  REMOVE_GROUP_SHIFT,
  REMOVE_SHIFT_FROM_GROUPS,
  RESET_STATE,
  SAVE_PHONE_NUMBERS,
  SAVE_SELECTED_ID,
  SAVE_SHORTAGES,
  SET_LOADING_REQUESTED,
  UPDATE_MODEL,
  UPDATE_MONTHLY_MODEL,
  UPDATE_TIME_BANK_BALANCE
} from "./mutation_types";
import {
  DELETE_SHIFT_TIME_DAY_DETAIL,
  SAVE_SHIFT_TIME_DAY_DETAIL
  //UPDATE_SHIFT_TIME
} from "../shifts/mutation_types";
import Vue from "vue";
// eslint-disable-next-line no-unused-vars
import { LocalDate, LocalDateTime } from "@js-joda/core";
import {
  //_get_client_first_day_of_month,
  //_get_client_first_day_of_month,
  _get_fdoms_for_month,
  _get_next_calendar_month,
  //_get_prev_calendar_month,
  _now /*, date_formatter*/
} from "../../time";
import {
  DONE_LOADING,
  SET_SCHEDULER_BUSY,
  SET_VALUE
} from "../../mutation-types";
import cloneDeep from "lodash.clonedeep";
import {
  QUEUE_SHORTAGE_REQUEST,
  SET_SELECTED_DAY
} from "../calendar/mutation_types";
import { initial_state } from "./state";
import {
  DELETE_MODEL,
  //DELETE_MONTHLY_MODEL,
  MODEL_UPDATE_MAP,
  SAVE_MODEL /*
  SAVE_MODEL_VALUE,
  SAVE_MONTHLY_MODEL,
  UPDATE_ASSET,
  UPDATE_GROUP,
  UPDATE_LOCATION,
  UPDATE_PERSON_TIME_OFF_TYPE,
  UPDATE_ROLE,
  UPDATE_SCHEDULE,
  UPDATE_TIME_BANK,
  UPDATE_TTFORM,
  UPDATE_TTFORMELEMENT*/
} from "../../constants/update_urls";
import { SET_ERRORS, SET_LOADING } from "../auth/mutation_types";
import * as Sentry from "@sentry/browser";
import { UPDATE_CUSTOM_FORM } from "../form/mutation_types";
import { setScope } from "../../utils";

export default {
  async [REMOVE_SHIFT_FROM_GROUPS]({ commit }, payload) {
    commit(REMOVE_SHIFT_FROM_GROUPS, payload);
  },
  async [REMOVE_GROUP_SHIFT]({ commit }, payload) {
    commit(REMOVE_GROUP_SHIFT, payload);
  },
  async [ADD_GROUP_SHIFT]({ commit }, payload) {
    commit(ADD_GROUP_SHIFT, payload);
  },
  async [LOAD_PHONE_NUMBERS]({ commit, dispatch, rootState }, payload) {
    let url = `${rootState.api_url}/phone_numbers/${rootState.client.id}/${payload.person_id}/`;
    // TODO: Send shift id after python is updated. For some reason people are
    //  being shipped with phone numbers... disable that at the same time.
    setScope(url);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    }).then(async response => {
      if (response.status === 401) {
        await dispatch(
          "auth/REFRESH_LOGIN",
          { action: "client/LOAD_PHONE_NUMBERS", payload: payload },
          { root: true }
        );
        return;
      }
      if (response.status === 200) {
        response.json().then(async jd => {
          if (jd) {
            Sentry.configureScope(scope => {
              scope.setExtra("json_response", jd);
            });
            await commit(SAVE_PHONE_NUMBERS, {
              numbers: jd,
              person: payload.person_id
            });
          }
        });
      }
    });
  },
  async [LOAD_TIME_BANK_BALANCES]({ commit, dispatch, rootState }, payload) {
    // data_set format
    // {person_id: 123, current_day: "2019-01-02"}
    if (window.was_offline_at_some_point) return;
    let request_payload = "";
    let url = rootState.api_url + `/time_banks/get_balances/`;
    if (rootState.client.id) {
      request_payload = JSON.stringify({
        client_id: rootState.client.id,
        data_set: [payload]
      });
    } else {
      request_payload = JSON.stringify({
        client_id: payload.client_id,
        data_set: [payload]
      });
    }
    setScope(url, request_payload);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      },
      body: request_payload
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 401) {
          await dispatch(
            "auth/REFRESH_LOGIN",
            { action: "client/LOAD_TIME_BANK_BALANCES", payload: payload },
            { root: true }
          );
          return;
        }
        if (response.status === 200) {
          response
            .json()
            .then(async jd => {
              if (jd) {
                Sentry.configureScope(scope => {
                  scope.setExtra("json_response", jd);
                });
                await commit("SAVE_TIME_BANK_BALANCES", jd);
              }
            })
            .catch(err => {
              Sentry.captureException(err);
              console.log(err);
            });
        }
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [SAVE_SELECTED_ID]({ commit }, payload) {
    await commit(SAVE_SELECTED_ID, payload);
  },
  async [DELETE_SHIFT_TIME_DAY_DETAIL]({ commit }, payload) {
    await commit(DELETE_SHIFT_TIME_DAY_DETAIL, payload);
  },
  async [LOAD_CLIENT]({ dispatch, commit, rootState }, client_id) {
    let url = rootState.api_url + `/load/` + client_id + `/`;
    setScope(url);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 500) {
          commit(SET_LOADING, false);
          commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
          ]);
          return;
        }
        if (response.status === 401) {
          await dispatch(
            "auth/REFRESH_LOGIN",
            { action: "client/LOAD_CLIENT", payload: client_id },
            { root: true }
          );
          return;
        }
        response
          .json()
          .then(async jd => {
            if (jd) {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              await commit("SAVE_INITIAL_CLIENT", jd.client);
              await commit(
                "auth/SAVE_INITIAL_PERSON",
                jd.client.people[jd.person],
                {
                  root: true
                }
              );
              await dispatch("calendar/CHECK_LOCKOUT", client_id, {
                root: true
              });
              await dispatch(
                "calendar/LOAD_DEFAULT_SCHEDULE",
                {},
                { root: true }
              );
              await dispatch(DONE_LOADING, {}, { root: true });
              /*
          const fdom = _get_client_first_day_of_month(
            _now(rootState.client.settings.timezone)
          );
          const next_fdom = _get_next_calendar_month(fdom);
          await dispatch(
            QUEUE_PERIOD,
            {
                  client_id: rootState.client.id,
                  period: next_fdom.toString(),
                  month: next_fdom.monthValue(),
                  year: next_fdom.year(),
                  day: next_fdom.dayOfMonth()
            },
            { root: false }
          );
          const prev_fdom = _get_prev_calendar_month(fdom);
          await dispatch(
            QUEUE_PERIOD,
            {
                  client_id: rootState.client.id,
                  period: prev_fdom.toString(),
                  month: prev_fdom.monthValue(),
                  year: prev_fdom.year(),
                  day: prev_fdom.dayOfMonth()},
            { root: false }
          );*/
            }
          })
          .catch(err => {
            Sentry.captureException(err);
            console.log(err);
          });
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [QUEUE_PERIOD]({ commit, state }, payload) {
    if (
      state.period_queue.includes(payload.period) ||
      state.period_list.includes(payload.period)
    ) {
      return;
    }
    await commit(SET_LOADING_REQUESTED, payload);
  },
  async [POP_PERIOD_REQUEST]({ commit, dispatch, state, rootState }) {
    //console.log(POP_PERIOD_REQUEST);
    if (rootState.scheduler_busy) {
      //console.log("busy");
      return;
    }
    if (state.period_queue.length === 0) {
      //console.log("empty");
      return false;
    }
    let period_request = state.period_queue[state.period_queue.length - 1];
    let period_request_data = cloneDeep(
      state.queued_period_data[period_request]
    );
    //console.log(period_request_data, period_request);
    await commit(DELETE_QUEUED_PERIOD_DATA, period_request);
    await dispatch(SET_SCHEDULER_BUSY, true, { root: true });
    if (period_request_data) {
      await dispatch(LOAD_PERIOD, period_request_data);
    } else {
      await dispatch(SET_SCHEDULER_BUSY, false, { root: true });
    }
    await commit(DELETE_PERIOD_FROM_QUEUE, period_request);
    //return true;
  },
  async [LOAD_PERIOD]({ dispatch, commit, rootState, state }, payload) {
    if (window.was_offline_at_some_point) return;
    let url = `${rootState.api_url}/load/${payload.client_id}/${payload.year}/${payload.month}/${payload.day}/`;
    setScope(url, payload);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(async response => {
        Vue.nextTick(() => {
          ///let response = Promise.resolve(resp);
          //console.log(response);
          Sentry.configureScope(scope => {
            scope.setExtra("api_response", response);
          });
          if (response.status === 500) {
            dispatch(SET_SCHEDULER_BUSY, false, { root: true });
            commit(SET_ERRORS, [
              "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
            ]);
            return;
          }
          if (response.status === 401) {
            dispatch(
              "auth/REFRESH_LOGIN",
              { action: "client/LOAD_PERIOD", payload: payload },
              { root: true }
            );
            return;
          }
          response
            .json()
            .then(async jd => {
              if (jd) {
                Sentry.configureScope(scope => {
                  scope.setExtra("json_response", jd);
                });
                commit("SAVE_PERIOD", jd.period);
                let period = Object.keys(jd.period)[0];
                let shifts = Object.values(jd.period[period].shifts);
                let changes = Object.values(jd.period[period].shift_changes);
                if (!state.initial_period_loaded) {
                  const loaded_schedule = rootState.calendar.default_schedule;
                  let default_schedule = null;
                  if (!loaded_schedule) {
                    let schedules = Object.values(jd.period[period].schedules);
                    let everything_schedule = null;
                    let first_schedule = schedules[0];
                    for (const schedule_index in schedules) {
                      const schedule = schedules[schedule_index];
                      if (
                        schedule.id === rootState.auth.person.default_schedule
                      )
                        default_schedule = schedule;
                      if (
                        Object.keys(jd.period[period].groups).filter(
                          group_key => {
                            return !schedule.groups.includes(
                              parseInt(group_key)
                            );
                          }
                        ).length <=
                        schedule.groups.length / 2
                      ) {
                        everything_schedule = schedule;
                      }
                      if (
                        !everything_schedule ||
                        schedule.id !== everything_schedule.id
                      ) {
                        for (const group_index in schedule.groups) {
                          const group = schedule.groups[group_index];
                          if (
                            jd.period[period].groups[group].people.includes(
                              rootState.auth.person.id
                            )
                          ) {
                            default_schedule = schedule;
                            break;
                          }
                        }
                      }
                      if (default_schedule) {
                        break;
                      }
                    }
                    if (!default_schedule) {
                      default_schedule = everything_schedule;
                    }
                    if (!default_schedule) {
                      default_schedule = first_schedule;
                    }
                  } else {
                    default_schedule = loaded_schedule;
                  }
                  const fdoms = _get_fdoms_for_month(LocalDate.parse(period));
                  if (default_schedule) {
                    await dispatch(
                      "calendar/SET_SELECTED_SCHEDULE",
                      {
                        target: { value: default_schedule.id },
                        fdoms: fdoms
                      },
                      {
                        root: true
                      }
                    );
                  } else {
                    await dispatch(
                      "calendar/SET_SELECTED_SCHEDULE",
                      {
                        target: { value: "all_schedules" },
                        fdoms: fdoms
                      },
                      {
                        root: true
                      }
                    );
                  }

                  Vue.nextTick(() => {
                    let now_date = _now(state.settings.timezone).toLocalDate();
                    dispatch(
                      "calendar/" + SET_SELECTED_DAY,
                      {
                        day: now_date,
                        period: period
                      },
                      { root: true }
                    );
                  });
                }

                Vue.nextTick(() => {
                  dispatch(
                    "shifts/SAVE_SHIFT_CHANGES",
                    { changes: changes, period: period },
                    { root: true }
                  );
                });
                Vue.nextTick(() => {
                  dispatch(
                    "shifts/SAVE_SHIFTS",
                    { shifts: shifts, period: period },
                    { root: true }
                  );
                });

                if (!state.initial_period_loaded || rootState.data_syncing) {
                  dispatch(DONE_LOADING, {}, { root: true });
                  await commit(INITIAL_PERIOD_LOADED);
                }

                Vue.nextTick(() => {
                  let end = _get_next_calendar_month(LocalDate.parse(period));

                  dispatch(
                    "calendar/" + QUEUE_SHORTAGE_REQUEST,
                    {
                      start: period,
                      end: end.toString(),
                      client_id: payload.client_id
                    },
                    { root: true }
                  );
                });
              }
              dispatch(SET_SCHEDULER_BUSY, false, { root: true });
            })
            .catch(error => {
              console.log(error);
              dispatch(SET_SCHEDULER_BUSY, false, { root: true });
            });
        });
      })
      .catch(error => {
        dispatch(SET_SCHEDULER_BUSY, false, { root: true });
        Sentry.captureException(error);
        commit(SET_ERRORS, [
          "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
        ]);
      });
  },
  async [RESET_STATE]({ commit }) {
    let state_keys = Object.keys(initial_state);
    for (let key of state_keys) {
      await commit(SET_VALUE, {
        key: key,
        value: cloneDeep(initial_state[key])
      });
    }
  },
  async [SAVE_SHIFT_TIME_DAY_DETAIL]({ commit }, payload) {
    await commit(SAVE_SHIFT_TIME_DAY_DETAIL, payload);
  },
  async [SAVE_SHORTAGES]({ commit }, payload) {
    await commit(SAVE_SHORTAGES, payload);
  },
  // eslint-disable-next-line no-unused-vars
  async [UPDATE_MODEL]({ dispatch, commit, rootState }, payload) {
    if (window.was_offline_at_some_point) return;
    let model_update_data = MODEL_UPDATE_MAP[payload.data.model];
    if (process.env.NODE_ENV !== "production") {
      console.log(UPDATE_MODEL, payload);
    }
    let url = model_update_data.update_url(payload.data.model_id);
    setScope(url, payload);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 500) {
          commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. " +
              "The developers have been notified and will fix this bug ASAP"
          ]);
          return;
        }
        if (response.status === 401) {
          await dispatch(
            "auth/REFRESH_LOGIN",
            { action: "client/UPDATE_MODEL", payload: payload },
            { root: true }
          );
          return;
        }
        if (response.status === 404) {
          await dispatch(
            model_update_data.module + "/" + DELETE_MODEL,
            {
              model_update_data: model_update_data,
              data: { id: payload.data.model_id },
              period: payload.data.first_day_of_month
            },
            { root: true }
          );
          if (
            ["custom_forms", "tt_form_element"].includes(payload.data.model)
          ) {
            await dispatch("form/" + UPDATE_CUSTOM_FORM, true, { root: true });
          }
          return;
        }
        response
          .json()
          .then(async jd => {
            if (jd) {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              let data = jd[payload.data.model_id];
              if (!data) {
                data = jd;
              }
              await dispatch(
                model_update_data.module + "/" + SAVE_MODEL,
                {
                  model_update_data: model_update_data,
                  data: data,
                  period: payload.data.first_day_of_month
                },
                { root: true }
              );
              if (
                ["custom_forms", "tt_form_element"].includes(payload.data.model)
              ) {
                await dispatch("form/" + UPDATE_CUSTOM_FORM, true, {
                  root: true
                });
              }
            }
          })
          .catch(error => {
            Sentry.captureException(error);
            console.log(error);
          });
      })
      .catch(error => {
        Sentry.captureException(error);
        commit(SET_ERRORS, [
          "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
        ]);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async [UPDATE_MONTHLY_MODEL]({ dispatch, commit, rootState }, payload) {
    if (window.was_offline_at_some_point) return;
    if (
      !Object.keys(rootState.client.periods).includes(
        payload.data.first_day_of_month
      )
    )
      return;
    let model_update_data = MODEL_UPDATE_MAP[payload.data.model];
    if (!model_update_data) return;

    let url = model_update_data.update_url(payload.data.model_id);
    setScope(url, payload);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });

        if (response.status === 500) {
          commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
          ]);
          return;
        }
        if (response.status === 401) {
          await dispatch(
            "auth/REFRESH_LOGIN",
            { action: "client/UPDATE_MONTHLY_MODEL", payload: payload },
            { root: true }
          );
          return;
        }
        if (response.status === 404) {
          await dispatch(
            model_update_data.module + "/" + DELETE_MODEL,
            {
              model_update_data: model_update_data,
              data: { id: payload.data.model_id },
              period: payload.data.first_day_of_month
            },
            { root: true }
          );
          return;
        }
        response
          .json()
          .then(async jd => {
            if (jd) {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              let id_exists = jd[payload.data.model_id];
              let data = null;
              if (id_exists) {
                data = jd[payload.data.model_id];
              } else {
                data = jd;
              }
              await dispatch(
                model_update_data.module + "/" + SAVE_MODEL,
                {
                  model_update_data: model_update_data,
                  data: data,
                  period: payload.data.first_day_of_month
                },
                { root: true }
              );
            }
          })
          .catch(error => {
            Sentry.captureException(error);
            console.log(error);
          });
      })
      .catch(error => {
        Sentry.captureException(error);
        commit(SET_ERRORS, [
          "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
        ]);
      });
  },
  async [SAVE_MODEL]({ commit }, payload) {
    await commit(SAVE_MODEL, payload);
  },
  async [DELETE_MODEL]({ commit }, payload) {
    await commit(DELETE_MODEL, payload);
  },
  // eslint-disable-next-line no-unused-vars
  async [UPDATE_TIME_BANK_BALANCE]({ commit, dispatch }, payload) {
    let day = LocalDate.parse(
      payload.data.current_date.split(" ")[0]
    ).toString();
    let data = {
      person_id: payload.data.person_id,
      current_day: day
    };
    Vue.nextTick(() => {
      dispatch(LOAD_TIME_BANK_BALANCES, data);
    });
  }
};
